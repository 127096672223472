import React, { useLayoutEffect, useRef } from 'react';
import { gsap } from 'gsap';
import Hamburger from '@/components/modules/Hamburger';
import MobileNavigation from '@/components/modules/MobileNavigation';
import Logo from '@/assets/images/logo.svg';
import { calcScrollStartPoint } from '@/utils/navigation';
import { INavigationItem } from '@/types';
import classNames from 'classnames';

interface Props {
  navigation: INavigationItem[];
  opened: boolean;
  onToggle: () => void;
  onClick: () => void;
  onTransitionEnd: (section: string) => void;
  scrollTo: React.MouseEventHandler<HTMLAnchorElement>;
}

function MobileMenu({
  navigation,
  opened,
  onToggle,
  onClick,
  onTransitionEnd,
  scrollTo,
}: Props) {
  const logo = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const logoOffset = 25;

    const timeline = gsap.timeline({
      scrollTrigger: {
        start: () => `+=${calcScrollStartPoint(`products`)} bottom`,
        end: `+=500`,
        scrub: 2,
        invalidateOnRefresh: true,
      },
    });

    timeline.to(logo.current, {
      y: () =>
        -(window.innerHeight / 2 - logo.current.scrollHeight / 2) + logoOffset,
      scale: 0.33,
    });

    gsap.set(logo.current, {
      scale: 0.33,
      autoAlpha: 0,
      y: 0,
    });

    gsap.to(logo.current, {
      duration: 1,
      scale: 1,
      autoAlpha: 1,
      ease: `back.out(1.7)`,
      delay: 1,
      immediateRender: false,
    });

    return () => {
      timeline.scrollTrigger.kill();
    };
  }, []);

  return (
    <header
      className={classNames(
        `mobile-menu fixed inset-0 w-screen h-screen pointer-events-none lg:hidden flex flex-col justify-between items-center p-7`,
        { 'z-100': !opened, 'z-110': opened }
      )}
    >
      <div className="pointer-events-auto">
        <Hamburger cross={opened} onClick={onToggle} />
      </div>
      <MobileNavigation
        navigation={navigation}
        opened={opened}
        onClick={onClick}
        onTransitionEnd={onTransitionEnd}
      />
      <div
        ref={logo}
        className={classNames(
          `mobile-menu__logo pointer-events-auto w-30 h-30 flex justify-center`,
          {
            '-z-1': opened,
          }
        )}
      >
        <a
          href="/"
          onClick={scrollTo}
          className={classNames(`block w-full h-full`)}
        >
          <Logo />
        </a>
      </div>
      <div className="invisible" />
    </header>
  );
}

export default MobileMenu;
