import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { DynamicModal as Props } from '@/types';
import ImageWrapper from '../ImageWrapper';
import CloseButton from '../CloseButton';

function DynamicModal({
  desktop,
  mobile,
  enabled,
  url,
  timeAfterToShow,
  showOnlyOneTimeForUser,
}: Props) {
  const [show, setShow] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const onClickBanner = useCallback(() => {
    if (!url) return;
    window.open(url, `_blank`);
  }, [url]);

  const onClose = useCallback((e?: React.MouseEvent<HTMLButtonElement>) => {
    if (e) e.stopPropagation();
    setShow(false);
  }, []);

  const onClick = useCallback((event: MouseEvent) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (ref.current && !ref.current.contains(event.target)) onClose();
  }, []);

  useEffect(() => {
    if (!enabled) return;

    if (showOnlyOneTimeForUser) {
      const alreadyShow = window.localStorage.getItem(`dynamicModal`);
      if (alreadyShow === `1`) return;
      window.localStorage.setItem(`dynamicModal`, `1`);
    }

    document.addEventListener(`click`, onClick);

    if (timeAfterToShow > 0) setTimeout(() => setShow(true), timeAfterToShow);
    else setShow(true);

    return () => {
      document.removeEventListener(`click`, onClick);
    };
  }, []);

  if (!enabled) return null;

  return (
    <div
      className={classNames(
        `fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-[100] lg:z-[90] flex justify-center w-full transition-all duration-500`,
        { 'invisible opacity-0': !show }
      )}
      ref={ref}
    >
      <ImageWrapper
        className={classNames(
          `h-[85vh] max-h-[800px] hidden md:block relative`,
          { 'cursor-pointer': !!url }
        )}
        data={desktop}
        objectFit="contain"
        layout="fixed"
        onClick={onClickBanner}
        alt=""
      >
        <div className="absolute top-0 right-0">
          <CloseButton onClick={onClose} size="big" color="red" />
        </div>
      </ImageWrapper>
      <ImageWrapper
        className={classNames(`h-[85vh] max-h-[700px] md:hidden relative`, {
          'cursor-pointer': !!url,
        })}
        data={mobile}
        objectFit="contain"
        layout="fixed"
        onClick={onClickBanner}
        alt=""
      >
        <div className="absolute top-0 right-0">
          <CloseButton onClick={onClose} size="medium" color="red" />
        </div>
      </ImageWrapper>
    </div>
  );
}

export default DynamicModal;
