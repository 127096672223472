import React, { useCallback } from 'react';
import ImageWrapper from '@/components/modules/ImageWrapper';
import { IGatsbyImageData } from 'gatsby-plugin-image';

interface Props {
  image?: IGatsbyImageData;
  name?: string;
  parent?: string;
  onClick?: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    urlAnchor?: any
  ) => void;
}

function DesignersGridItem({ image, name, parent, onClick }: Props) {
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      onClick(null, name);
    },
    [name]
  );

  return (
    <div className="designers-grid-item aspect-square relative">
      {name && (
        <a href={`?section=${parent}&subsection=${name}`} onClick={handleClick}>
          <ImageWrapper className="w-full h-full" data={image} alt={name} />
          <div className="w-full h-full flex bg-[rgba(195,205,210,0.75)] opacity-0 hover:opacity-100 duration-500 justify-center items-center absolute top-0 left-0 p-2">
            <span className="font-bold text-[20px] text-center capitalize text-red">
              {name}
            </span>
          </div>
        </a>
      )}
      {!name && image && (
        <ImageWrapper className="w-full h-full" data={image} alt="" />
      )}
    </div>
  );
}

export type { Props };
export default DesignersGridItem;
