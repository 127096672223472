import React from 'react';

export interface Props {
  label: string;
  type: 'text' | 'email';
  name: string;
  required?: boolean;
  value: string;
  onChange: (name: string, value: string) => void;
}

function FormInput({ label, type, name, required, value, onChange }: Props) {
  return (
    <div className="w-full flex flex-col">
      <p className="font-df-camino text-18 leading-30">{label}</p>
      <input
        type={type}
        name={name}
        required={required}
        value={value}
        onChange={(e) => onChange(name, e.target.value)}
        className="w-full bg-transparent text-18 leading-30 border-b-[1px] border-silver-sand focus:outline-none font-extralight"
      />
    </div>
  );
}

export default FormInput;
