export const snakeToCamel = (str: string): string =>
  str
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace(`-`, ``).replace(`_`, ``)
    );

export const snakeKeysToCamel = (data) =>
  Object.keys(data).reduce((obj, key) => {
    obj[snakeToCamel(key)] = data[key];
    return obj;
  }, {});

export const setOffScreen = (
  element: HTMLDivElement,
  horizontal: boolean
): number =>
  horizontal
    ? window.screen.width - element.offsetWidth / 2
    : window.screen.height - element.offsetWidth / 2;

export const iOS = () =>
  [
    `iPad Simulator`,
    `iPhone Simulator`,
    `iPod Simulator`,
    `iPad`,
    `iPhone`,
    `iPod`,
  ].includes(navigator.platform) ||
  // iPad on iOS 13 detection
  (navigator.userAgent.includes(`Mac`) && `ontouchend` in document);

export const splitParagraph = (str: string, minLength: number): string[] => {
  if (str.length > minLength) {
    const words = str.split(` `);
    const str1 = words.slice(0, words.length / 2).join(` `);
    const str2 = words.slice(words.length / 2, words.length).join(` `);
    return [str1, str2];
  }
  return [str, ``];
};

export const isMobile = () =>
  /Android|webOS|iPhone|iPad|iPod|iPhone Simulator|iPad Simulator|iPod Simulator|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

export const isIpadOS = () =>
  navigator.maxTouchPoints &&
  navigator.maxTouchPoints > 2 &&
  /MacIntel/.test(navigator.platform);
