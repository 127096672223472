import React, { useContext, useMemo, useRef } from 'react';
import { Context as HorizontalScrollingContext } from '@/components/modules/HorizontalScrolling';
import HorizontalSection from '@/components/modules/HorizontalSection';
import { HorizontalSectionProps } from '@/types';
import AnimatedArrow from '@/components/animations/AnimatedArrow';
import ParallaxFloatElement from '@/components/modules/ParallaxFloatElement';
import HomeArrow from '@/assets/images/arrows/home-arrow.svg';
import HomeCurves from '@/components/modules/HomeCurves';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { FileNode } from 'gatsby-plugin-image/dist/src/components/hooks';
import Fade from '@/components/animations/Fade';

export interface Props
  extends Omit<HorizontalSectionProps, 'containerAnimation' | 'trigger'> {
  firstText?: string;
  secondText?: string;
  thirdText?: string;
  iosFallbackImage?: IGatsbyImageData;
}

type StaticData = {
  azzProduct: FileNode;
  diverDesktop: FileNode;
};

const query = graphql`
  query Data {
    azzProduct: file(name: { eq: "azz-orografie-web" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    diverDesktop: file(name: { eq: "diver" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;

function Home({
  name,
  background,
  separator,
  mobileRatio,
  desktopRatio,
  firstText,
  secondText,
  thirdText,
}: Props) {
  const section = useRef<HTMLDivElement>(null);
  const containerAnimation = useContext<React.MutableRefObject<GSAPAnimation>>(
    HorizontalScrollingContext
  );
  const { azzProduct, diverDesktop } = useStaticQuery<StaticData>(query);

  const elements = useMemo(
    () => [
      {
        item: {
          id: 1,
          name: `diver`,
          image: { data: getImage(diverDesktop) },
          maxRadius: 30,
          maxSpeed: 10,
        },
      },
      {
        item: {
          id: 2,
          name: `azz`,
          image: { data: getImage(azzProduct) },
          maxRadius: 30,
          maxSpeed: 10,
        },
      },
    ],
    []
  );

  return (
    <HorizontalSection
      ref={section}
      name={name}
      containerAnimation={containerAnimation}
      trigger={section}
      background={background}
      separator={separator}
      mobileRatio={mobileRatio}
      desktopRatio={desktopRatio}
    >
      <div className="home relative text-right w-full h-full flex">
        <Fade delay={1}>
          <HomeCurves
            containerAnimation={containerAnimation}
            trigger={section}
            firstText={firstText}
            secondText={secondText}
            thirdText={thirdText}
            delay={1}
          />
        </Fade>
        <div className="home__elements-group absolute bottom-0 right-[45vh] md:right-[40vh]">
          <div className="relative">
            <AnimatedArrow
              className="home__arrow absolute w-[50vh] bottom-0 -left-1/3 text-eerie-black hidden lg:block"
              containerAnimation={containerAnimation}
              trigger={section}
              delay={1}
              horizontal
            >
              <HomeArrow className="fill-current w-full h-full" />
            </AnimatedArrow>
            <Fade delay={1}>
              <ParallaxFloatElement
                className="home__astronaut relative z-20 w-[25.96vh]"
                containerAnimation={containerAnimation}
                trigger={section}
                horizontal
                movement={-60}
                maxRadius={elements[0].item.maxRadius}
                maxSpeed={elements[0].item.maxSpeed}
                data={elements[0].item.image.data}
                alt="diver"
              />
            </Fade>
          </div>
        </div>
        {/*
        <Fade delay={1}>
          <a
            href="https://pocopiano.orografie.com/"
            target="_blank"
            rel="noreferrer"
          >
            <div className="home__pocopiano-text-container absolute top-[10vh] left-[10vh] lg:left-[25vh]">
              <Fade delay={1}>
                <a
                  href="https://pocopiano.orografie.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <ParallaxFloatElement
                    className="home__pocopiano-text relative z-20 w-[30vh] lg:w-[50vh]"
                    containerAnimation={containerAnimation}
                    trigger={section}
                    horizontal
                    movement={-60}
                    maxRadius={elements[2].item.maxRadius}
                    maxSpeed={elements[2].item.maxSpeed}
                    data={elements[2].item.image.data}
                    alt="Pocopiano"
                  />
                </a>
              </Fade>
            </div>
          </a>
        </Fade>
        */}
        {/* <Fade delay={1}>
          <a
            href="https://pocopiano.orografie.com/"
            target="_blank"
            rel="noreferrer"
          >
            <div className="home__pocopiano-container absolute top-[25vh] left-[12vh] w-[25vh] lg:left-[35vh]">
              <Fade delay={1}>
                <a
                  href="https://pocopiano.orografie.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <ParallaxFloatElement
                    className="home__pocopiano-btn relative z-20 w-[23vh] lg:w-[30vh]"
                    containerAnimation={containerAnimation}
                    trigger={section}
                    horizontal
                    movement={-60}
                    maxRadius={elements[3].item.maxRadius}
                    maxSpeed={elements[3].item.maxSpeed}
                    data={elements[3].item.image.data}
                    alt="Pocopiano"
                  />
                </a>
              </Fade>
            </div>
          </a>
      </Fade> */}
        <Fade delay={1}>
          <div className="home__azz-container absolute bottom-[12vh] lg:bottom-[10vh] lg:left-[-5vh] left-[-2vh]">
            <Fade delay={1}>
              <ParallaxFloatElement
                className="home__azz relative z-20 w-[40vh] lg:w-[90vh]"
                containerAnimation={containerAnimation}
                trigger={section}
                horizontal
                movement={-60}
                maxRadius={elements[1].item.maxRadius}
                maxSpeed={elements[1].item.maxSpeed}
                data={elements[1].item.image.data}
                alt="Azz"
              />
            </Fade>
          </div>
        </Fade>
      </div>
    </HorizontalSection>
  );
}

export default Home;
