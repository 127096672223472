/*import React from 'react';
import MenuItem from '@/components/modules/MenuItem';
import { INavigationItem } from '@/types';

interface Props {
  navigation: INavigationItem[];
  currentSection: string;
  onMouseEnter?: (timeline?: GSAPTimeline) => void;
  onMouseLeave?: (timeline?: GSAPTimeline) => void;
  onClick: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    urlAnchor?: any
  ) => void;
}

function ArrowLinks({
  navigation,
  currentSection,
  onMouseEnter,
  onMouseLeave,
  onClick,
}: Props) {
  const logoUrl =
    'https://d98wxao0fcbqm.cloudfront.net/Logo_orografie_0161d1d13e.png';

  return (
    <div className="arrow-links fixed top-0 w-full z-50">
      <a href="/" onClick={onClick}>
        <img
          src={logoUrl}
          alt="Logo Orografie"
          className="fixed top-2 left-3 w-9 h-8"
        />
      </a>
      <div className="arrow-links__items flex justify-end">
        {navigation.map(({ name, startPoint, label }) => (
          <MenuItem
            key={name}
            name={name}
            label={label}
            startPoint={startPoint}
            active={name === currentSection}
            onClick={onClick}
          />
        ))}
      </div>
    </div>
  );
}

export default ArrowLinks;*/

import React, { useState } from 'react';
import MenuItem from '@/components/modules/MenuItem';
import { INavigationItem } from '@/types';

interface Props {
  navigation: INavigationItem[];
  currentSection: string;
  onMouseEnter?: (timeline?: GSAPTimeline) => void;
  onMouseLeave?: (timeline?: GSAPTimeline) => void;
  onClick: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    urlAnchor?: any
  ) => void;
}

function ArrowLinks({
  navigation,
  currentSection,
  onMouseEnter,
  onMouseLeave,
  onClick,
}: Props) {
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);

  const logoUrl =
    'https://d98wxao0fcbqm.cloudfront.net/Logo_orografie_0161d1d13e.png';

  return (
    <div className="arrow-links fixed top-0 w-full z-50">
      <a href="/" onClick={onClick}>
        <img
          src={logoUrl}
          alt="Logo Orografie"
          className="fixed top-2 left-3 w-9 h-8"
        />
      </a>
      <div className="arrow-links__items flex justify-end relative">
        {navigation.map(({ name, startPoint, label }) => (
          <div
            key={name}
            className="relative"
            onMouseEnter={() => setHoveredItem(name)}
            onMouseLeave={() => setHoveredItem(null)}
          >
            <MenuItem
              name={name}
              label={label}
              startPoint={startPoint}
              active={name === currentSection}
              onClick={onClick}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default ArrowLinks;
