import classNames from 'classnames';
import React from 'react';

interface Props {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  size?: 'big' | 'medium' | 'normal';
  color?: 'red' | 'black';
}

function CloseButton({ onClick, size = `normal`, color = `black` }: Props) {
  return (
    <button
      onClick={onClick}
      className={classNames(
        `close-button text-18 w-10 h-11 relative border-red border-b-[1px] border-l-[1px]`,
        { 'w-13 h-13 text-40': size === `big` },
        { 'w-10 h-12 text-30': size === `medium` },
        { 'text-red': color === `red` }
      )}
      type="button"
    >
      X
    </button>
  );
}

export default CloseButton;
