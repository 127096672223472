import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';

const LineWithTriangle = () => {
  const lineRef = useRef<HTMLDivElement>(null);
  const triangleRef1 = useRef<HTMLDivElement>(null);
  const triangleRef2 = useRef<HTMLDivElement>(null);
  const triangleRef3 = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const horizontalScrollContainer = document.querySelector(
      '.first-horizontal-section'
    );
    const lineWidth = lineRef.current.offsetWidth;

    const commonAnimationConfig = {
      scrollTrigger: {
        trigger: horizontalScrollContainer,
        start: 'top left',
        end: '+=15000',
        scrub: 1,
      },
    };

    // Animazione per il primo triangolo
    gsap.to(triangleRef1.current, {
      x: () => `${lineWidth - triangleRef1.current.offsetWidth}px`,
      ...commonAnimationConfig,
    });

    // Animazione per il secondo triangolo
    gsap.to(triangleRef2.current, {
      x: () => `${lineWidth - triangleRef2.current.offsetWidth - 100}px`,
      ...commonAnimationConfig,
    });

    // Animazione per il terzo triangolo
    gsap.to(triangleRef3.current, {
      x: () => `${lineWidth - triangleRef3.current.offsetWidth - 100}px`,
      ...commonAnimationConfig,
    });
  }, []);

  return (
    <div
      className="linewithtriangle"
      style={{
        position: 'relative',
        height: '25px',
      }}
    >
      <div
        ref={lineRef}
        className="line"
        style={{
          position: 'absolute',
          top: '-15%',
          left: '0',
          width: '100%',
          height: '2px',
          backgroundColor: '#Af2e3e',
          opacity: 0,
        }}
      />

      <div
        ref={triangleRef1}
        className="triangle1"
        style={{
          position: 'absolute',
          top: '-11%',
          left: '40%',
          width: '25px',
          height: '25px',
          backgroundColor: '#Af2e3e',
          clipPath: 'polygon(0% 0%, 100% 50%, 0% 100%)',
          transform: 'translateY(-50%)',
        }}
      />

      <div
        ref={triangleRef2}
        className="triangle2"
        style={{
          position: 'absolute',
          top: '-61%',
          left: '70%',
          width: '25px',
          height: '25px',
          backgroundColor: '#Af2e3e',
          clipPath: 'polygon(0% 0%, 100% 50%, 0% 100%)',
        }}
      />

      <div
        ref={triangleRef3}
        className="triangle3"
        style={{
          position: 'absolute',
          top: '-61%',
          left: 'calc(70% + 23px)',
          width: '25px',
          height: '25px',
          backgroundColor: '#Af2e3e',
          clipPath: 'polygon(0% 0%, 100% 50%, 0% 100%)',
        }}
      />
    </div>
  );
};

export default LineWithTriangle;
