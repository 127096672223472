import { IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import ImageWrapper from '@/components/modules/ImageWrapper';

export interface Props {
  title: string;
  image: IGatsbyImageData;
  alt: string;
  description: string;
  textLeft?: boolean;
  separator?: 'none' | 'small' | 'large';
}

function TeamMember({
  title,
  description,
  textLeft = false,
  image,
  alt,
}: Props) {
  return (
    <>
      <div className="w-full h-full overflow-hidden max-w-50">
        <ImageWrapper
          className="w-auto h-full"
          data={image}
          objectFit="contain"
          objectPosition={textLeft ? `left` : `right`}
          alt={alt}
        />
      </div>
      <div
        className={`w-full h-full max-w-50 flex flex-col justify-start items-center text-wine-red ${
          textLeft ? `text-left` : `text-right`
        }`}
      >
        <p className="w-full font-df-camino text-[2.25vh] leading-[2.25vh] md:text-[2.22vh] md:leading-[2.66vh]">
          {title}
        </p>
        <p className="w-full font-df-camino font-light whitespace-pre-line text-[1.875vh] leading-[1.875vh] md:text-[1.77vh] md:leading-[1.88vh]">
          {description}
        </p>
      </div>
    </>
  );
}

export default TeamMember;
