import React, { useLayoutEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import ImageWrapper from '@/components/modules/ImageWrapper';
import Parallax from '@/components/animations/Parallax';
import SideMenu from '@/components/modules/SideMenu';
import ProductsMenu from '@/components/modules/ProductsMenu';
import { Background, Image, INavigationItem, SectionProps } from '@/types';
import useSectionMenu from '@/hooks/useSectionMenu';
import Fade from '@/components/animations/Fade';

interface Props extends SectionProps {
  navigationItems: INavigationItem[];
  background: Background[];
  cover: Image;
  startPoint: number;
  endPoint: number;
  onClick?: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    urlAnchor?: any
  ) => void;
}

function ProductsHead({
  name,
  navigationItems = [],
  background = [],
  cover,
  startPoint,
  endPoint,
  onClick,
}: Props) {
  const section = useRef<HTMLDivElement>(null);
  const [
    root,
    sectionTrigger,
    createTrigger,
    isMenuVisible,
    isSideMenuOpen,
    handleSideMenuToggle,
    handleSideMenuClick,
    currentSubSection,
  ] = useSectionMenu(startPoint, endPoint);

  useLayoutEffect(() => {
    if (!startPoint || !endPoint) return;
    if (sectionTrigger) sectionTrigger.kill();
    createTrigger();
  }, [startPoint, endPoint]);

  return (
    <section
      id={name}
      ref={section}
      className="products-head relative w-screen h-screen"
    >
      <div className="products-head__bg h-full">
        {background.map((bg, i) => (
          <Fade key={`layer-${i}`} className="h-full" delay={bg.delay}>
            <Parallax
              className={`${i > 0 ? `absolute inset-0` : `h-full`}`}
              trigger={section}
              movement={bg.parallaxMovement}
              horizontal
            >
              <ImageWrapper
                data={bg.data}
                mobile={bg.mobile}
                className="h-full"
                objectFit="contain"
                objectPosition="right"
                alt=""
              />
            </Parallax>
          </Fade>
        ))}
      </div>
      <Fade delay={1}>
        <div className="products-head__cover absolute inset-0 z-5">
          <ImageWrapper
            className="w-full h-full"
            data={cover?.data}
            mobile={cover?.mobile}
            objectFit="cover"
            objectPosition="center"
            alt=""
          />
        </div>
      </Fade>
      {root &&
        createPortal(
          <>
            <ProductsMenu
              navigationItems={navigationItems}
              currentSection={currentSubSection}
              isVisible={isMenuVisible}
              onClick={onClick}
            />
            <SideMenu
              label="prodotti"
              items={navigationItems}
              open={isSideMenuOpen}
              isVisible={isMenuVisible}
              onToggle={handleSideMenuToggle}
              onClick={handleSideMenuClick}
            />
          </>,
          root
        )}
    </section>
  );
}

export default ProductsHead;
