import React from 'react';
import { INavigationItem } from '@/types';
import DesignersMenuItem from '@/components/modules/DesignersMenuItem';
import classNames from 'classnames';
import './style.scss';

interface Props {
  navigationItems: INavigationItem[];
  currentSection: string;
  isVisible: boolean;
  onClick: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    urlAnchor?: any
  ) => void;
}

function DesignersMenu({
  navigationItems,
  currentSection,
  isVisible,
  onClick,
}: Props) {
  return (
    <nav
      id="designers-menu"
      className={classNames(
        `designers-menu hidden fixed top-35 bottom-14 z-10 space-y-1 flex-col lg:flex lg:right-[15vw] xl:right-[18.61vw]`,
        {
          'fade-out pointer-events-none': !isVisible,
          'fade-in': isVisible,
        }
      )}
    >
      {navigationItems.map((item) => (
        <DesignersMenuItem
          key={item.name}
          {...item}
          active={item.name === currentSection}
          onClick={onClick}
        />
      ))}
      <div className="lg:w-1 lg:h-10" />
    </nav>
  );
}

export default DesignersMenu;
