import React, { useRef } from 'react';

const Line2 = () => {
  const lineRef2 = useRef<HTMLDivElement>(null);

  return (
    <div
      className="line2"
      style={{
        position: 'relative',
        height: '25px',
      }}
    >
      <div
        ref={lineRef2}
        className="line"
        style={{
          position: 'absolute',
          top: '50.8%',
          left: '0',
          width: '100%',
          height: '2px',
          backgroundColor: '#Af2e3e',
          transform: 'translateY(-50%)',
        }}
      />
    </div>
  );
};

export default Line2;
