import React, { useContext, useLayoutEffect, useRef } from 'react';
import { gsap } from 'gsap';
import ArrowLinks from '@/components/modules/ArrowLinks';
import { INavigationItem } from '@/types';
import Logo from '@/assets/images/logo.svg';
import { calcScrollStartPoint } from '@/utils/navigation';
import { Context as SectionObserverContext } from '@/components/modules/SectionObserver';
import LineWithTriangle from '@/components/modules/LineWithTriangle';
import Line from '@/components/modules/Line';

interface Props {
  navigation: INavigationItem[];
  onClick: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    urlAnchor?: any
  ) => void;
}

const DesktopMenu = ({ navigation, onClick }: Props) => {
  const sectionObserverCtx = useContext(SectionObserverContext);
  const logo = useRef<HTMLAnchorElement>(null);
  const triangle = useRef<HTMLDivElement>(null);
  const line = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const introTl = gsap.timeline();
    const productSection = document.querySelector('.products-body'); // Sostituisci con il selettore corretto della tua sezione

    // Animazione di ingresso logo
    introTl.fromTo(
      logo.current,
      { opacity: 0 },
      { opacity: 1, duration: 5, delay: 1, ease: 'power3.out' }
    );

    // Animazione di ingresso linea
    introTl.fromTo(
      triangle.current,
      { x: '-100%', opacity: 0 },
      { x: '0%', opacity: 1, duration: 3, ease: 'power3.out' },
      '<'
    );

    introTl.fromTo(
      line.current,
      { x: '-100%', opacity: 0 },
      { x: '0%', opacity: 1, duration: 3, ease: 'power3.out' },
      '<'
    );

    // Timeline GSAP per animare logo e linea durante lo scroll
    const logoAndLineTl = gsap.timeline({
      scrollTrigger: {
        start: () => `+=${calcScrollStartPoint(`products`)} bottom`,
        end: `+=700`,
        scrub: 4,
        invalidateOnRefresh: true,
      },
    });

    logoAndLineTl
      .to(logo.current, {
        top: () => {
          const header = document.querySelector('header'); // Trova l'header
          const headerHeight = header ? header.offsetHeight : 0; // Altezza dell'header

          // Posiziona il logo sotto l'header con un offset fisso
          const fixedOffset = 120; // Offset fisso in pixel
          return `${headerHeight + fixedOffset}px`;
        },
        scale: 0.5,
        duration: 3,
      })

      .to(line.current, {
        x: '100%',
        scale: 1,
        duration: 3,
      });

    // Timeline per far sparire il logo dopo la sezione "products"
    const hideLogoTl = gsap.timeline({
      scrollTrigger: {
        trigger: productSection,
        start: 'bottom bottom',
        end: 'bottom+=100 bottom',
        scrub: true,
      },
    });

    hideLogoTl.to(logo.current, {
      opacity: 0,
      duration: 3,
      ease: 'power3.out',
    });

    return () => {
      introTl.kill();
      logoAndLineTl.kill();
      hideLogoTl.kill();
    };
  }, []);

  return (
    <>
      <header className="hidden lg:block fixed top-0 left-0 w-full h-13 border-2 border-t-0 border-l-0 border-red z-100 bg-transparent">
        <nav className="desktop-menu hidden relative w-full lg:block">
          <ArrowLinks
            navigation={navigation}
            currentSection={sectionObserverCtx.currentSection}
            onClick={onClick}
          />
        </nav>
      </header>

      <a
        ref={logo}
        href="/"
        className="desktop-menu__logo hidden lg:block fixed top-1/2 py-[0.75rem] left-1/2 -translate-x-1/2 -translate-y-1/2  w-35 h-35 z-100"
        onClick={onClick}
      >
        <Logo />
      </a>

      <div
        ref={triangle}
        className="line-with-triangle hidden lg:block fixed top-1/2 py-[0.160rem] z-110 w-full"
      >
        <LineWithTriangle />
      </div>

      <div
        ref={line}
        className="line-with-triangle2 hidden lg:block fixed top-1/2 -mt-[0.8rem] z-1 w-full"
      >
        <Line />
      </div>
    </>
  );
};

export default DesktopMenu;
