import React, {
  useCallback,
  useState,
  useMemo,
  useLayoutEffect,
  createContext,
} from 'react';
import SectionObserver from '@/components/modules/SectionObserver';
import {
  BaseComponentProps,
  DynamicModal,
  Info,
  Link,
  Navigation,
  SocialLink,
} from '@/types';
import DesktopMenu from '@/components/modules/DesktopMenu';
import MobileMenu from '@/components/modules/MobileMenu';
import Footer from '@/components/modules/Footer';
import ContactsPanel from '@/components/modules/ContactsPanel';
// import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import { isIpadOS, isMobile } from '@/utils/helpers';
import { app } from '@/utils/app';
import { loadRecaptcha } from '@/utils/recaptcha';
import SwitchLanguage from '../modules/SwitchLanguage';
import CDynamicModal from '../modules/DynamicModal';

interface Props extends BaseComponentProps {
  navigation: Navigation;
  icons: SocialLink[];
  footerLink: Link;
  info: Info;
  modal: DynamicModal;
  scrollTo: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    value: string
  ) => void | React.MouseEventHandler<HTMLAnchorElement>;
  imageUrl: string;
}

export const Context = createContext(null);

function DefaultLayout({
  navigation,
  icons,
  footerLink,
  children,
  info,
  modal,
  scrollTo,
  imageUrl,
}: Props) {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [disableEffects, setDisableEffects] = useState(false);

  const settings = useMemo(
    () => ({
      appear: !disableEffects,
      parallax: !disableEffects,
      fade: !disableEffects,
      float: !disableEffects,
      homeCurves: !disableEffects,
    }),
    [disableEffects]
  );

  const handleToggleMenu = useCallback(() => {
    setIsMenuOpen(!isMenuOpen);

    /* if (!isMenuOpen) {
      disableBodyScroll(document.body);
      return;
    }
    enableBodyScroll(document.body); */
  }, [isMenuOpen]);

  const handleMenuTransitionEnd = useCallback(
    (section: string) => {
      if (!section) return;
      scrollTo(null, section);
    },
    [scrollTo]
  );

  useLayoutEffect(() => {
    setDisableEffects(isMobile() || isIpadOS());
    loadRecaptcha();
  }, []);

  return (
    <Context.Provider value={settings}>
      <SectionObserver navigation={navigation} scrollTo={scrollTo}>
        {app.structure.navigationReady && (
          <>
            <MobileMenu
              navigation={navigation.main}
              opened={isMenuOpen}
              onToggle={handleToggleMenu}
              onClick={handleToggleMenu}
              onTransitionEnd={handleMenuTransitionEnd}
              scrollTo={scrollTo as React.MouseEventHandler<HTMLAnchorElement>}
            />
            <DesktopMenu navigation={navigation.main} onClick={scrollTo} />
            <div
              className="fixed left-0.5 transform -translate-y-1/2 z-100"
              style={{ top: `70vh` }}
            >
              <img
                src={imageUrl}
                alt="compasso-d-oro-menzione-d-onore-adi"
                className="w-16 h-auto object-cover md:w-19 lg:w-22"
                style={{ transform: `rotate(12deg)` }}
              />
            </div>
            <ContactsPanel {...info} openButtonLabel="Info" />

            <SwitchLanguage />
            <CDynamicModal {...modal} />
          </>
        )}
        <main className="overflow-hidden">{children}</main>
        <Footer icons={icons} link={footerLink} />
      </SectionObserver>
    </Context.Provider>
  );
}

export type { Props };
export default DefaultLayout;
