import React, {
  useMemo,
  CSSProperties,
  useState,
  useCallback,
  ReactNode,
} from 'react';
import { IGatsbyImageData, withArtDirection } from 'gatsby-plugin-image';
import { useInView } from 'react-intersection-observer';
import { encode } from 'universal-base64';
import classNames from 'classnames';

const isSsr = typeof window === `undefined`;

const isIntersectionObserverAvailable = isSsr
  ? false
  : !!(window as any).IntersectionObserver;

type ImageState = {
  lazyLoad: boolean;
  inView: boolean;
  loaded: boolean;
};

const imageAddStrategy = ({ lazyLoad, inView, loaded }: ImageState) => {
  if (!lazyLoad) {
    return true;
  }

  if (isSsr) {
    return false;
  }

  if (isIntersectionObserverAvailable) {
    return inView || loaded;
  }

  return true;
};

const imageShowStrategy = ({ lazyLoad, loaded }: ImageState) => {
  if (!lazyLoad) {
    return true;
  }

  if (isSsr) {
    return false;
  }

  if (isIntersectionObserverAvailable) {
    return loaded;
  }

  return true;
};

interface Props {
  data: IGatsbyImageData;
  mobile?: IGatsbyImageData;
  alt: string;
  className?: string;
  layout?: 'constrained' | 'fixed';
  intersectionThreshold?: number;
  intersectionMargin?: string;
  objectFit?: CSSProperties['objectFit'];
  objectPosition?: CSSProperties['objectPosition'];
  onLoad?: () => void;
  children?: ReactNode;
  lazyLoad?: boolean;
  onClick?: () => void;
  loading?: 'lazy' | 'eager';
}

function ImageWrapper({
  data,
  mobile,
  alt,
  className,
  layout = `constrained`,
  intersectionThreshold,
  intersectionMargin,
  objectFit,
  objectPosition,
  onLoad,
  children,
  onClick,
  lazyLoad = false,
}: Props) {
  const [loaded, setLoaded] = useState<boolean>(false);

  const image = useMemo(() => {
    if (mobile) {
      return withArtDirection(data, [
        { media: `(max-width: 1024px)`, image: mobile },
      ]);
    }
    return data;
  }, [data, mobile]);

  if (!image) return null;

  const constrainedLayout: CSSProperties = {
    position: `relative`,
    width: `100%`,
    maxWidth: image.width,
  };

  const absolutePositioning: CSSProperties = {
    position: `absolute`,
    left: 0,
    top: 0,
    width: `100%`,
    height: `100%`,
  };

  const sizer = useMemo(() => {
    const { width } = image;
    const aspectRatio = width / image.height;
    const height = image.height || width / aspectRatio;

    const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}"></svg>`;

    return (
      <img
        className="w-full block"
        src={`data:image/svg+xml;base64,${encode(svg)}`}
        aria-hidden="true"
        alt=""
      />
    );
  }, [image]);

  const sources = useMemo(
    () =>
      image.images.sources &&
      image.images.sources.map(({ media, srcSet, sizes, type }, i) => (
        <source
          key={`source-${i}`}
          media={media}
          srcSet={srcSet}
          sizes={sizes || null}
          type={type}
        />
      )),
    [image]
  );

  const [viewRef, inView] = useInView({
    threshold: intersectionThreshold || 0,
    rootMargin: intersectionMargin || `0px 30% 30% 0px`,
    triggerOnce: true,
    fallbackInView: true,
  });

  const addImage = imageAddStrategy({
    lazyLoad,
    inView,
    loaded,
  });

  const showImage = imageShowStrategy({
    lazyLoad,
    inView,
    loaded,
  });

  const handleLoad = useCallback(() => {
    onLoad?.();
    setLoaded(true);
  }, []);

  return (
    <div
      ref={viewRef}
      style={{
        ...(layout !== `fixed` ? constrainedLayout : null),
      }}
      onClick={onClick}
      aria-hidden="true"
      className={`image-wrapper ${className || ``} overflow-hidden`}
    >
      {layout !== `fixed` && sizer}
      {addImage && (
        <picture>
          {sources}
          {image.images.fallback.src && (
            <img
              style={{
                ...(layout !== `fixed`
                  ? absolutePositioning
                  : { height: `100%` }),
                objectFit,
                objectPosition,
              }}
              className={classNames(`transition-opacity duration-300`, {
                'opacity-0': !showImage,
                'opacity-1': showImage,
              })}
              src={image.images.fallback.src}
              srcSet={image.images.fallback.srcSet}
              sizes={image.images.fallback.sizes}
              onLoad={handleLoad}
              alt={alt || ``}
            />
          )}
        </picture>
      )}
      <noscript>
        <picture>
          {sources}
          {image.images.fallback.src && (
            <img
              src={image.images.fallback.src}
              style={{ ...absolutePositioning }}
              loading="lazy"
              alt={alt || ``}
            />
          )}
        </picture>
      </noscript>
      {children}
    </div>
  );
}

export type { Props };
export default ImageWrapper;
