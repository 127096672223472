import React from 'react';
import DesignersList from '@/components/modules/DesignersList';
import { Props as DesignerProps } from '@/components/modules/Designer';
import ImageWrapper from '@/components/modules/ImageWrapper';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { FileNode } from 'gatsby-plugin-image/dist/src/components/hooks';

interface Props {
  designers: DesignerProps[];
}

const query = graphql`
  query {
    horizontalSeparator: file(name: { eq: "separatore-orizzontale" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    designerBackground: file(name: { eq: "designer-background" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;

type StaticData = {
  horizontalSeparator: FileNode;
  designerBackground: FileNode;
};

function DesignersBody({ designers }: Props) {
  const data = useStaticQuery<StaticData>(query);
  const horizontalSeparator = getImage(data.horizontalSeparator);
  const designerBackground = getImage(data.designerBackground);

  return (
    <section className="designers-body">
      <DesignersList designers={designers} background={designerBackground} />
      <div className="py-30">
        <ImageWrapper data={horizontalSeparator} alt="" />
      </div>
    </section>
  );
}

export default DesignersBody;
