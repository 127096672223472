/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback, useState } from 'react';
import FormInput from '@/components/modules/FormInput';
import FormTextArea from '@/components/modules/FormTextArea';
import axios from 'axios';
import { Input } from '@/types';

export interface Props {
  title: string;
  inputs: Input[];
  submitLabel: string;
  textarea: Input;
}

const createForm = (inputs: Input[]) => {
  const obj: { [key: string]: string } = {};
  inputs.forEach((input) => {
    obj[input.name] = ``;
  });
  return obj;
};

function FormContacts({ title, inputs, submitLabel, textarea }: Props) {
  const [data, setData] = useState(createForm([...inputs, textarea]));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(``);
  const [success, setSuccess] = useState(false);

  const onChange = useCallback(
    (name: string, value: string) => {
      setData({
        ...data,
        [name]: value,
      });
    },
    [data]
  );

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault();
      if (loading) return;
      let check = false;
      [...inputs, textarea].forEach((el) => {
        if (el.required && !data[el.name]) {
          setError(`Incomplete fields`);
          check = true;
        }
      });
      if (check) return;
      setLoading(true);
      // @ts-ignore
      window.grecaptcha.ready(() => {
        // @ts-ignore
        window.grecaptcha
          .execute(process.env.GATSBY_GOOGLE_RECAPTCHA_CLIENT_KEY, {
            action: `submit`,
          })
          .then(async (token) => {
            console.log(token);
            try {
              await axios.post(`/api/contact`, { ...data, token });
              setSuccess(true);
              setTimeout(() => setSuccess(false), 4000);
              setData(createForm([...inputs, textarea]));
            } catch (e) {
              setError(
                `Si è verificato un errore, per favore contatta info@orografie.com`
              );
            } finally {
              setLoading(false);
            }
          });
      });
    },
    [data, loading]
  );

  return (
    <form
      name="contact"
      className="form-contacts flex flex-col justify-start items-center pb-8"
      onSubmit={onSubmit}
    >
      <div className="form-contacts__title w-full border-b-[1px] border-red px-6 py-1">
        <p className="text-18 leading-35">{title}</p>
      </div>
      <div className="form-contacts__data w-full flex flex-col lg:flex-row gap-y-3 gap-x-6 lg:gap-x-9 pl-6 pr-18 ">
        <div className="w-full flex flex-col pt-10 gap-y-3 lg:w-1/3 lg:py-9">
          {inputs.map(({ type, label, name, required }, index) => (
            <FormInput
              key={index.toString()}
              type={type}
              name={name}
              label={label}
              required={required}
              value={data[name]}
              onChange={onChange}
            />
          ))}
        </div>
        <div className="hidden lg:flex border-l-[1px] border-red" />
        <div className="w-full flex flex-col pb-18 lg:w-1/2 lg:py-9">
          {textarea !== undefined && (
            <FormTextArea
              label={textarea.label}
              name={textarea.name}
              required={textarea.required}
              value={data[textarea.name]}
              onChange={onChange}
            />
          )}
        </div>
      </div>
      <div className="form-contacts__submit w-full border-t-[1px] border-red flex flex-col items-end">
        <button
          type="submit"
          disabled={loading}
          className="h-9 w-24 border-l-[1px] border-b-[1px] lg:border-r-[1px] lg:mr-12 border-red text-18 leading-30"
        >
          {submitLabel}
        </button>
        {error && (
          <p className="text-red text-18 leading-35 py-2 mr-2 lg:mr-12">
            {error}
          </p>
        )}
        {success && (
          <p className="text-blue-sapphire text-18 leading-35 py-2 mr-2 lg:mr-12">
            Il messaggio è stato inviato con successo!
          </p>
        )}
      </div>
    </form>
  );
}

export default FormContacts;
