import React, { useContext, useRef } from 'react';
import { HorizontalSectionProps, Image, ParallaxImage } from '@/types';
import HorizontalSection from '@/components/modules/HorizontalSection';
import { Context as HorizontalScrollingContext } from '@/components/modules/HorizontalScrolling';
import EmergentEntry, {
  Props as EmergentEntryProps,
} from '@/components/modules/EmergentEntry';
import Arrow01 from '@/assets/images/arrows/emergent-arrow-01.svg';
import Arrow02 from '@/assets/images/arrows/emergent-arrow-02.svg';
import Arrow03 from '@/assets/images/arrows/emergent-arrow-03.svg';
import Parallax from '@/components/animations/Parallax';
import ImageWrapper from '@/components/modules/ImageWrapper';
import Fade from '@/components/animations/Fade';

interface Props
  extends Omit<HorizontalSectionProps, 'containerAnimation' | 'trigger'> {
  headingLogo: Image;
  text: string;
  elements: ParallaxImage[];
  entries: Pick<EmergentEntryProps, `images` | `text`>[];
  entriesOddBackground: ParallaxImage[];
  entriesSeparator: Image;
}

function Emergent({
  name,
  background,
  separator,
  mobileRatio,
  desktopRatio,
  headingLogo,
  text,
  elements,
  entries,
  entriesOddBackground,
  entriesSeparator,
}: Props) {
  const section = useRef<HTMLDivElement>(null);
  const containerAnimation = useContext<React.MutableRefObject<GSAPAnimation>>(
    HorizontalScrollingContext
  );

  return (
    <div id={name} className="emergent flex h-screen">
      <div className="relative z-10">
        <HorizontalSection
          ref={section}
          containerAnimation={containerAnimation}
          trigger={section}
          background={background}
          separator={separator}
          mobileRatio={mobileRatio}
          desktopRatio={desktopRatio}
        >
          <Fade delay={1}>
            <Parallax
              className="emergent__arrow-01 absolute top-0 left-0 bottom-0 z-1"
              containerAnimation={containerAnimation}
              trigger={section}
              movement={background[0].parallaxMovement}
              horizontal
            >
              <Arrow01 className="h-full" />
            </Parallax>
          </Fade>
          <Fade delay={1}>
            <div className="emergent__text absolute top-[32.22vh] left-[45vh] whitespace-pre-line w-[35.55vh]">
              <ImageWrapper
                className="w-[20.45vh] mb-6"
                data={headingLogo?.data}
                alt={headingLogo?.alt}
              />
              <p className="font-light text-wine-red text-[2.125vh] leading-[2.5vh] lg:text-[2vh] lg:leading-[2.33vh]">
                {text}
              </p>
            </div>
          </Fade>
          <Fade delay={1}>
            <Arrow03 className="emergent__arrow-03 absolute bottom-0 right-[52vh] h-[36vh] z-1 lg:right-[44vh]" />
            <Arrow02 className="emergent__arrow-02 absolute top-0 right-[7.5vh] bottom-0 h-full z-1 translate-x-1/2" />
          </Fade>
          {elements.map((el, i) => (
            <Fade key={`element-${i}`} delay={1}>
              <Parallax
                className="emergent__illustration absolute bottom-0 w-[46.5vh] right-[13vh] lg:right-[5vh]"
                containerAnimation={containerAnimation}
                trigger={section}
                movement={el.parallaxMovement}
                horizontal
              >
                <ImageWrapper data={el.data} alt="" />
              </Parallax>
            </Fade>
          ))}
        </HorizontalSection>
      </div>
      {entries.map((entry, i) => (
        <Fade key={`entry-${i}`}>
          <EmergentEntry
            containerAnimation={containerAnimation}
            trigger={section}
            background={i % 3 === 0 ? entriesOddBackground : []}
            entrySeparator={entriesSeparator}
            mobileRatio={i % 3 === 0 ? 1.42 : 1.4}
            desktopRatio={i % 2 === 0 ? 1.7 : desktopRatio}
            images={entry.images}
            text={entry.text}
            first={i === 0}
          />
        </Fade>
      ))}
    </div>
  );
}

export type { Props };
export default Emergent;
