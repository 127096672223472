/*import React, { useRef } from 'react';
import { Image, SectionProps } from '@/types';
import Product, { Props as ProductProps } from '@/components/modules/Product';
import Fade from '@/components/animations/Fade';
import { graphql, useStaticQuery } from 'gatsby';
import { FileNode } from 'gatsby-plugin-image/dist/src/components/hooks';
import ImageWrapper from '@/components/modules/ImageWrapper';
import { getImage } from 'gatsby-plugin-image';

interface Props extends SectionProps {
  background: Image;
  items: ProductProps[];
}

type StaticData = {
  horizontalSeparator: FileNode;
};

const query = graphql`
  query {
    horizontalSeparator: file(name: { eq: "prodotti-separatore_02" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;

function ProductsBody({ name, background, items = [] }: Props) {
  const data = useStaticQuery<StaticData>(query);
  const section = useRef<HTMLDivElement>(null);
  return (
    <section id={name} ref={section} className="products-body pb-50">
      {items.map((product) => (
        <Fade key={product.id}>
          <Product background={background} {...product} />
          <ImageWrapper data={getImage(data.horizontalSeparator)} alt="" />
        </Fade>
      ))}
    </section>
  );
}

export type { Props };
export default ProductsBody;*/

import React, { useMemo, Suspense } from 'react';
import { Image, SectionProps } from '@/types';
import Fade from '@/components/animations/Fade';
import { graphql, useStaticQuery } from 'gatsby';
import { FileNode } from 'gatsby-plugin-image/dist/src/components/hooks';
import ImageWrapper from '@/components/modules/ImageWrapper';
import { getImage } from 'gatsby-plugin-image';
import { Props as ProductProps } from '@/components/modules/Product';

// ⚠️ IMPORTAZIONE NORMALE (lazy non supporta props complesse)
import Product from '@/components/modules/Product';

interface Props extends SectionProps {
  background: Image;
  items: ProductProps[];
}

type StaticData = {
  horizontalSeparator: FileNode;
};

const query = graphql`
  query {
    horizontalSeparator: file(name: { eq: "prodotti-separatore_02" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;

function ProductsBody({ name, background, items = [] }: Props) {
  const data = useStaticQuery<StaticData>(query);

  const separatorImage = useMemo(
    () => getImage(data.horizontalSeparator),
    [data]
  );

  return (
    <section
      id={name}
      className="products-body pb-50 w-full flex flex-col items-center"
    >
      {items.map((product) => (
        <Fade key={product.id}>
          {/* Lazy Loading del prodotto, ma con loading a livello di immagine */}
          <Suspense
            fallback={<div className="text-center">Caricamento...</div>}
          >
            <Product background={background} {...product} />
          </Suspense>
          {/* Lazy Loading per il separatore */}
          <ImageWrapper data={separatorImage} alt="" />
        </Fade>
      ))}
    </section>
  );
}

export type { Props };
export default ProductsBody;
