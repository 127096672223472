import React from 'react';
import './style.scss';

interface Props {
  onClick: () => void;
  cross: boolean;
}

function Hamburger({ onClick, cross }: Props) {
  return (
    <button
      className="hamburger w-6 h-6 relative flex flex-col z-110"
      type="button"
      onClick={onClick}
    >
      <span
        className={`hamburger__line w-full h-[2px] absolute top-0 duration-500 rounded-sm bg-black ${
          cross ? `hamburger__line--cross` : ``
        }`}
      >
        &nbsp;
      </span>
      <span
        className={`hamburger__line w-full h-[2px] absolute top-[8px] duration-500 rounded-sm bg-black ${
          cross ? `hamburger__line--cross` : ``
        }`}
      >
        &nbsp;
      </span>
      <span
        className={`hamburger__line w-full h-[2px] absolute top-16px duration-500 rounded-sm bg-black ${
          cross ? `hamburger__line--cross` : ``
        }`}
      >
        &nbsp;
      </span>
    </button>
  );
}

export default Hamburger;
