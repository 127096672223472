/*import React, { useState, useEffect, useMemo } from 'react';
import ImageWrapper from '@/components/modules/ImageWrapper';
import IconLink, {
  Props as IconLinkProps,
} from '@/components/modules/IconLink';
import { Image, Link } from '@/types';
import { splitParagraph } from '@/utils/helpers';
import ReactMarkdown from 'react-markdown';
import { handleUploadUrl } from '@/utils/strapi';
import messagesIt from '@/locales/it.json';
import messagesEn from '@/locales/en.json';

export interface Props {
  id: string;
  background?: Image;
  coverImage?: Image;
  title: string;
  author: string;
  year: string;
  description: string;
  links: Link[];
  socials: IconLinkProps[];
  productUrl: string;
  productImage?: Image;
  productGallery: Image[];
}

function Product({
  id,
  background,
  coverImage,
  title,
  author,
  year,
  description,
  links = [],
  socials = [],
  productUrl,
  productImage,
  productGallery = [],
}: Props) {
  const [lang, setLang] = useState(`it`);
  const paragraphs = useMemo(
    () => splitParagraph(description, 500),
    [description]
  );
  useEffect(() => {
    if (window?.location?.pathname.includes(`/en`)) {
      setLang(`en`);
      return;
    }
    setLang(`it`);
  }, []);
  return (
    <div
      id={id}
      className="product relative z-1 w-full flex flex-col justify-start items-center first:pt-50 pt-25 pb-25 last:pb-50"
    >
      <div className="product__cover relative z-1 w-full max-w-[800px] hidden justify-center items-center pr-14 md:flex lg:pr-0">
        <ImageWrapper data={coverImage.data} alt={coverImage.alt} />
      </div>
      <div className="product__content relative font-light text-left text-matisse w-full min-h-102 flex flex-col justify-between items-center pt-12 px-4 pb-4 -mt-6 lg:px-0">
        <div className="product__background hidden absolute inset-0 md:block">
          <ImageWrapper
            className="w-full h-full"
            data={background.data}
            objectFit="cover"
            objectPosition="center"
            alt=""
          />
        </div>
        <div className="w-full mb-6 relative max-w-[800px] flex flex-col justify-center items-start pr-18 lg:pr-0">
          <p className="title w-full text-30 leading-36">{title}</p>
          <p className="creator w-full text-19 leading-23 mb-4">
            {author}, {year}
          </p>
          <div className="product__description w-full text-19 leading-23 lg:flex lg:gap-x-50">
            {paragraphs.map((paragraph, i) => (
              <div
                key={`paragraph-${i}`}
                className="product__description-col lg:flex-1"
              >
                <ReactMarkdown className="product__description-text">
                  {paragraph}
                </ReactMarkdown>
              </div>
            ))}
          </div>
        </div>
        {(links.length > 0 || socials.length > 0) && (
          <div className="w-full relative max-w-[800px] flex flex-col justify-center items-start pl-2 border-l-[1px] border-matisse border-solid">
            <div className="w-max flex flex-col gap-2 mb-4 text-18 lg:text-16 leading-22 lg:leading-20 text-matisse font-df-camino">
              {links.map(({ url, label, file }, i) => {
                let cUrl = url;
                if (file?.url) {
                  cUrl = handleUploadUrl(file.url);
                }
                return (
                  <a
                    key={`link-${i}`}
                    href={cUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {label}
                  </a>
                );
              })}
            </div>
            <div className="text-matisse flex items-center gap-x-2">
              {socials.map((social) => (
                <IconLink
                  key={social.type}
                  className="w-12px h-12px"
                  {...social}
                />
              ))}
            </div>
          </div>
        )}
      </div>
      {productUrl && (
        <div className="product__buy-btn relative w-full max-w-[880px]">
          <div className="ml-4 md:ml-0 md:absolute bottom-0 right-[12%] md:translate-y-1/2">
            <a
              href={productUrl}
              className="font-df-camino font-light text-17 leading-21 text-white bg-metallic-seaweed h-11 w-36 rounded-full flex items-center justify-center"
              type="button"
            >
              {lang === `en`
                ? messagesEn.products.buy
                : messagesIt.products.buy}
            </a>
          </div>
        </div>
      )}
      <div className="product__image hidden w-full max-w-[800px] justify-center items-center mt-12 pr-18 md:flex lg:pr-0">
        <ImageWrapper
          className="w-full"
          data={productImage.data}
          alt={productImage.alt}
        />
      </div>
      <div className="product__gallery w-full max-w-[800px] flex flex-col pl-4 pr-18 space-y-4 mt-12 md:hidden">
        {productGallery.map(({ data, alt }, i) => (
          <ImageWrapper
            key={`image-${i}`}
            className="w-full"
            data={data}
            alt={alt}
          />
        ))}
      </div>
    </div>
  );
}

export default Product;*/
import React, { useState, useEffect, useMemo } from 'react';
import ImageWrapper from '@/components/modules/ImageWrapper';
import IconLink, {
  Props as IconLinkProps,
} from '@/components/modules/IconLink';
import { Image, Link } from '@/types';
import { splitParagraph } from '@/utils/helpers';
import ReactMarkdown from 'react-markdown';
import { handleUploadUrl } from '@/utils/strapi';
import messagesIt from '@/locales/it.json';
import messagesEn from '@/locales/en.json';

export interface Props {
  id: string;
  background?: Image;
  coverImage?: Image;
  title: string;
  author: string;
  year: string;
  description: string;
  links: Link[];
  socials: IconLinkProps[];
  productUrl: string;
  productImage?: Image;
  productGallery: Image[];
}

function Product({
  id,
  background,
  coverImage,
  title,
  author,
  year,
  description,
  links = [],
  socials = [],
  productUrl,
  productImage,
  productGallery = [],
}: Props) {
  const [lang, setLang] = useState(`it`);
  const paragraphs = useMemo(
    () => splitParagraph(description, 500),
    [description]
  );

  useEffect(() => {
    if (window?.location?.pathname.includes(`/en`)) {
      setLang(`en`);
      return;
    }
    setLang(`it`);
  }, []);

  return (
    <div
      id={id}
      className="product relative z-1 w-full flex flex-col justify-start items-center first:pt-50 pt-25 pb-25 last:pb-50"
    >
      {/* ✅ Lazy Load per l'immagine di copertina */}
      {coverImage && (
        <div className="product__cover relative z-1 w-full max-w-[800px] hidden justify-center items-center pr-14 md:flex lg:pr-0">
          <ImageWrapper
            data={coverImage.data}
            alt={coverImage.alt}
            loading="lazy"
          />
        </div>
      )}

      <div className="product__content relative font-light text-left text-matisse w-full min-h-102 flex flex-col justify-between items-center pt-12 px-4 pb-4 -mt-6 lg:px-0">
        {/* ✅ Lazy Load per il background */}
        {background && (
          <div className="product__background hidden absolute inset-0 md:block">
            <ImageWrapper
              className="w-full h-full"
              data={background.data}
              objectFit="cover"
              objectPosition="center"
              alt=""
              loading="lazy"
            />
          </div>
        )}

        <div className="w-full mb-6 relative max-w-[800px] flex flex-col justify-center items-start pr-18 lg:pr-0">
          <p className="title w-full text-30 leading-36">{title}</p>
          <p className="creator w-full text-19 leading-23 mb-4">
            {author}, {year}
          </p>
          <div className="product__description w-full text-19 leading-23 lg:flex lg:gap-x-50">
            {paragraphs.map((paragraph, i) => (
              <div
                key={`paragraph-${i}`}
                className="product__description-col lg:flex-1"
              >
                <ReactMarkdown className="product__description-text">
                  {paragraph}
                </ReactMarkdown>
              </div>
            ))}
          </div>
        </div>

        {(links.length > 0 || socials.length > 0) && (
          <div className="w-full relative max-w-[800px] flex flex-col justify-center items-start pl-2 border-l-[1px] border-matisse border-solid">
            <div className="w-max flex flex-col gap-2 mb-4 text-18 lg:text-16 leading-22 lg:leading-20 text-matisse font-df-camino">
              {links.map(({ url, label, file }, i) => {
                let cUrl = url;
                if (file?.url) {
                  cUrl = handleUploadUrl(file.url);
                }
                return (
                  <a
                    key={`link-${i}`}
                    href={cUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {label}
                  </a>
                );
              })}
            </div>
            <div className="text-matisse flex items-center gap-x-2">
              {socials.map((social) => (
                <IconLink
                  key={social.type}
                  className="w-12px h-12px"
                  {...social}
                />
              ))}
            </div>
          </div>
        )}
      </div>
      {productUrl && (
        <div className="product__buy-btn relative w-full max-w-[880px]">
          <div className="ml-4 md:ml-0 md:absolute bottom-0 right-[12%] md:translate-y-1/2">
            <a
              href={productUrl}
              className="font-df-camino font-light text-17 leading-21 text-white bg-metallic-seaweed h-11 w-36 rounded-full flex items-center justify-center"
              type="button"
            >
              {lang === `en`
                ? messagesEn.products.buy
                : messagesIt.products.buy}
            </a>
          </div>
        </div>
      )}
      {/* ✅ Lazy Load per l'immagine principale del prodotto */}
      {productImage && (
        <div className="product__image hidden w-full max-w-[800px] justify-center items-center mt-12 pr-18 md:flex lg:pr-0">
          <ImageWrapper
            className="w-full"
            data={productImage.data}
            alt={productImage.alt}
            loading="lazy"
          />
        </div>
      )}

      {/* ✅ Lazy Load per la Galleria di Immagini */}
      {productGallery.length > 0 && (
        <div className="product__gallery w-full max-w-[800px] flex flex-col pl-4 pr-18 space-y-4 mt-12 md:hidden">
          {productGallery.map(({ data, alt }, i) => (
            <ImageWrapper
              key={`image-${i}`}
              className="w-full"
              data={data}
              alt={alt}
              loading="lazy"
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default Product;
