import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Curves from '@/assets/images/home-curves/curves.svg';
import Triangle from '@/assets/images/home-curves/triangle.svg';
import Circle from '@/assets/images/home-curves/circle.svg';
import { app } from '@/utils/app';
import { useDebounce } from '@/hooks/useDebounce';
import Characters from '@/components/modules/Characters';

interface Props {
  containerAnimation: React.MutableRefObject<GSAPAnimation>;
  trigger: React.MutableRefObject<HTMLDivElement>;
  firstText?: string;
  secondText?: string;
  thirdText?: string;
  delay?: number;
}

function HomeCurves({
  containerAnimation,
  trigger,
  firstText = ``,
  secondText = ``,
  thirdText = ``,
  delay = 0,
}: Props) {
  const masterTl = useRef<GSAPTimeline>(gsap.timeline());
  const motionTl = useRef<GSAPTimeline[]>([]);
  const linesGroup = useRef<HTMLDivElement>(null);
  const lines = useRef<SVGPathElement[]>([]);
  const elements = useRef<HTMLDivElement[]>([]);
  const text = useRef<HTMLSpanElement[]>([]);
  const [enableMotion, setEnableMotion] = useState<boolean>(false);

  const draw = useCallback(() => {
    const timeline: GSAPTimeline = gsap.timeline();

    lines.current.forEach((line) => {
      const length = line.getTotalLength();
      timeline.set(line, {
        strokeDasharray: length,
        strokeDashoffset: length,
      });
    });

    timeline.to(lines.current, {
      strokeDashoffset: 0,
      delay,
      duration: () => Math.random() * (3 - 1) + 1,
      onComplete: () => setEnableMotion(true),
    });

    return timeline;
  }, []);

  const addToLine = useCallback(
    (
      index: number,
      group: Array<SVGElement | HTMLSpanElement>,
      nLine: number,
      origin?: [number, number],
      rotation?: number | boolean,
      delayBetween?: gsap.NumberValue | gsap.StaggerVars
    ) => {
      motionTl.current[index].to(
        group,
        {
          motionPath: {
            path: lines.current[nLine],
            align: lines.current[nLine],
            alignOrigin: origin || [0.5, 0.5],
            autoRotate: rotation || true,
          },
          ease: `none`,
          stagger: delayBetween || 0.01,
          immediateRender: true,
        },
        0
      );
    },
    []
  );

  const animateFirstGroup = useCallback(() => {
    let progress = 0;
    if (motionTl.current[0]) {
      progress = motionTl.current[0].progress();
      motionTl.current[0].kill();
    }

    motionTl.current[0] = gsap.timeline({
      scrollTrigger: {
        containerAnimation: containerAnimation?.current,
        trigger: trigger?.current,
        start: `top top+=20%`,
        end: `70% center-=20%`,
        scrub: 2,
      },
    });

    const elements01 = gsap.utils.toArray<SVGElement>(
      `svg`,
      elements.current[0]
    );

    addToLine(0, elements01, 6, null, 90);
    motionTl.current[0].progress(progress);

    return motionTl.current[0];
  }, []);

  const animateSecondGroup = useCallback(() => {
    let progress = 0;
    if (motionTl.current[2]) {
      progress = motionTl.current[2].progress();
      motionTl.current[2].kill();
    }

    motionTl.current[2] = gsap.timeline({
      scrollTrigger: {
        containerAnimation: containerAnimation?.current,
        trigger: trigger?.current,
        start: `top 80%`,
        end: `center center-=20%`,
        scrub: 2,
      },
    });

    const elements02 = gsap.utils.toArray<SVGElement>(
      `svg`,
      elements.current[1]
    );

    addToLine(2, elements02, 12, null, 90, 0.012);
    motionTl.current[2].progress(progress);

    return motionTl.current[2];
  }, []);

  const animateThirdGroup = useCallback(() => {
    let progress = 0;
    if (motionTl.current[3]) {
      progress = motionTl.current[3].progress();
      motionTl.current[3].kill();
    }

    motionTl.current[3] = gsap.timeline({
      scrollTrigger: {
        containerAnimation: containerAnimation?.current,
        trigger: trigger?.current,
        start: `top top+=5%`,
        end: `70% center-=20%`,
        scrub: 2,
      },
    });

    const elements03 = gsap.utils.toArray<SVGElement>(
      `svg`,
      elements.current[2]
    );

    addToLine(3, elements03, 13, null, 90, 0.012);
    motionTl.current[3].progress(progress);

    return motionTl.current[3];
  }, []);

  const animateFourthGroup = useCallback(() => {
    let progress = 0;
    if (motionTl.current[4]) {
      progress = motionTl.current[4].progress();
      motionTl.current[4].kill();
    }

    motionTl.current[4] = gsap.timeline({
      scrollTrigger: {
        containerAnimation: containerAnimation?.current,
        trigger: trigger?.current,
        start: `top top+=10%`,
        end: `60% center-=20%`,
        scrub: 2,
      },
    });

    const elements04 = gsap.utils.toArray<SVGElement>(
      `svg`,
      elements.current[3]
    );

    addToLine(4, elements04, 13, null, 90, 0.012);
    motionTl.current[4].progress(progress);

    return motionTl.current[4];
  }, []);

  const animateFifthGroup = useCallback(() => {
    let progress = 0;
    if (motionTl.current[5]) {
      progress = motionTl.current[5].progress();
      motionTl.current[5].kill();
    }

    motionTl.current[5] = gsap.timeline({
      scrollTrigger: {
        containerAnimation: containerAnimation?.current,
        trigger: trigger?.current,
        start: `top top+=20%`,
        end: `60% center-=20%`,
        scrub: 2,
      },
    });

    const elements05 = gsap.utils.toArray<SVGElement>(
      `svg`,
      elements.current[4]
    );

    addToLine(5, elements05, 15, null, 90, 0.012);
    motionTl.current[5].progress(progress);

    return motionTl.current[5];
  }, []);

  const animateTextGroup = useCallback(() => {
    let progress = 0;
    if (motionTl.current[1]) {
      progress = motionTl.current[1].progress();
      motionTl.current[1].kill();
    }

    ScrollTrigger.matchMedia({
      '(min-width: 1920px)': function () {
        motionTl.current[1] = gsap.timeline({
          scrollTrigger: {
            containerAnimation: containerAnimation?.current,
            trigger: trigger?.current,
            start: `top 95%`,
            end: `200% 40%`,
            scrub: 2,
          },
        });
      },
      '(min-width: 1025px) and (max-width: 1919px)': function () {
        motionTl.current[1] = gsap.timeline({
          scrollTrigger: {
            containerAnimation: containerAnimation?.current,
            trigger: trigger?.current,
            start: `top 85%`,
            end: `150% 40%`,
            scrub: 2,
          },
        });
      },
      '(min-width: 768px) and (max-width: 1024px)': function () {
        motionTl.current[1] = gsap.timeline({
          scrollTrigger: {
            containerAnimation: containerAnimation?.current,
            trigger: trigger?.current,
            start: `top 80%`,
            end: `100% 40%`,
            scrub: 2,
          },
        });
      },
      '(max-width: 767px)': function () {
        motionTl.current[1] = gsap.timeline({
          scrollTrigger: {
            containerAnimation: containerAnimation?.current,
            trigger: trigger?.current,
            start: `top 80%`,
            end: `65% 38%`,
            scrub: 2,
          },
        });
      },
    });

    const text01 = gsap.utils.toArray<HTMLSpanElement>(`span`, text.current[0]);
    const text02 = gsap.utils.toArray<HTMLSpanElement>(`span`, text.current[1]);
    const text03 = gsap.utils.toArray<HTMLSpanElement>(`span`, text.current[2]);

    addToLine(1, text01, 9, null, null, { each: 0.007 });
    addToLine(1, text02, 10, null, null, { each: 0.007 });
    addToLine(1, text03, 11, null, null, { each: 0.007 });
    motionTl.current[1].progress(progress);

    return motionTl.current[1];
  }, []);

  const addMotion = useCallback(() => {
    masterTl.current.add(animateFirstGroup());
    masterTl.current.add(animateTextGroup());
    masterTl.current.add(animateSecondGroup());
    masterTl.current.add(animateThirdGroup());
    masterTl.current.add(animateFourthGroup());
    masterTl.current.add(animateFifthGroup());
  }, []);

  const debounceAddMotion = useDebounce(addMotion, 500);

  useLayoutEffect(() => {
    if (!app.structure.ready) return;

    lines.current = gsap.utils.toArray<SVGPathElement>(
      `.line`,
      linesGroup.current
    );

    if (app.structure.canAnimate) masterTl.current.add(draw());
  }, [app.structure.ready]);

  useEffect(() => {
    if (!enableMotion) return;
    addMotion();
    window.addEventListener(`resize`, debounceAddMotion);

    return () => {
      window.removeEventListener(`resize`, debounceAddMotion);
    };
  }, [enableMotion]);

  return (
    <div ref={linesGroup} className="home-curves h-full flex">
      <div className="home-curves__content h-[70vmax] mt-auto md:h-[100vmin]">
        <Curves className="relative -left-[32%] h-full lg:left-0" />
        <div
          ref={(el) => elements.current.push(el)}
          className="home-curves__elements-01"
        >
          <Triangle className="w-3 lg:w-4" />
          <Triangle className="w-3 lg:w-4" />
          <Triangle className="w-3 lg:w-4" />
          <Circle className="w-3 lg:w-4" />
        </div>
        <Characters
          ref={(el) => text.current.push(el)}
          className="home-curves__text-01 font-light text-blue-sapphire lg:text-22 lg:leading-none"
          text={firstText}
        />
        <Characters
          ref={(el) => text.current.push(el)}
          className="home-curves__text-01 font-light text-blue-sapphire lg:text-22 lg:leading-none"
          text={secondText}
        />
        <Characters
          ref={(el) => text.current.push(el)}
          className="home-curves__text-01 font-light text-blue-sapphire lg:text-22 lg:leading-none"
          text={thirdText}
        />
        <div
          ref={(el) => elements.current.push(el)}
          className="home-curves__elements-02"
        >
          <Triangle className="w-3 lg:w-4" />
          <Triangle className="w-3 lg:w-4" />
          <Triangle className="w-3 lg:w-4" />
        </div>
        <div
          ref={(el) => elements.current.push(el)}
          className="home-curves__elements-03"
        >
          <Triangle className="w-3 lg:w-4" />
          <Triangle className="w-3 lg:w-4" />
          <Triangle className="w-3 lg:w-4" />
        </div>
        <div
          ref={(el) => elements.current.push(el)}
          className="home-curves__elements-04"
        >
          <Triangle className="w-3 lg:w-4" />
          <Triangle className="w-3 lg:w-4" />
          <Triangle className="w-3 lg:w-4" />
        </div>
        <div
          ref={(el) => elements.current.push(el)}
          className="home-curves__elements-05"
        >
          <Triangle className="w-3 lg:w-4" />
          <Triangle className="w-3 lg:w-4" />
          <Triangle className="w-3 lg:w-4" />
        </div>
      </div>
    </div>
  );
}

export default HomeCurves;
