export const loadScriptByURL = (
  id: string,
  url: string,
  callback?: () => void
) => {
  const isScriptExist = document.getElementById(id);

  if (!isScriptExist) {
    const script = document.createElement(`script`);
    script.type = `text/javascript`;
    script.src = url;
    script.id = id;
    script.onload = function () {
      if (callback) callback();
    };
    document.body.appendChild(script);
  }

  if (isScriptExist && callback) callback();
};

export const loadRecaptcha = () => {
  loadScriptByURL(
    `recaptcha`,
    `https://www.google.com/recaptcha/api.js?render=${process.env.GATSBY_GOOGLE_RECAPTCHA_CLIENT_KEY}`
  );
};
