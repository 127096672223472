import React from 'react';
import { Background, SectionProps, SectionSeparator } from '@/types';
import TeamSeparator from '@/components/modules/TeamSeparator';
import TeamMember, {
  Props as TeamMemberProps,
} from '@/components/modules/TeamMember';
import ImageWrapper from '@/components/modules/ImageWrapper';
import Fade from '@/components/animations/Fade';
import Arrow from '@/assets/images/team-arrow.svg';

export interface Props extends SectionProps {
  elements: TeamMemberProps[];
  background: Background[];
  separator: SectionSeparator;
}

function Team({ name, elements = [], background }: Props) {
  return (
    <section
      id={name}
      className="team relative text-right h-screen p-6 pl-[36vh] pt-32 flex justify-center items-center"
    >
      <div className="team__arrow absolute inset-0 right-auto h-full">
        <Arrow className="w-full h-full" />
      </div>
      <div className="absolute top-0 left-0 w-full h-full -z-10">
        <Fade delay={background[0].delay}>
          <ImageWrapper
            data={background[0].data}
            className="absolute top-0 left-0 h-full"
            layout="fixed"
            alt=""
          />
        </Fade>
        <Fade delay={background[1].delay}>
          <ImageWrapper
            data={background[1].data}
            className="absolute top-0 left-0 h-full"
            layout="fixed"
            alt=""
          />
        </Fade>
      </div>
      <div className="w-full h-full grid auto-cols-[minmax(0px,_fit-content)] grid-flow-col grid-rows-[minmax(0,_350px)_min-content] content-end gap-y-[2.5vh] lg:gap-y-[4.44vh]">
        {elements.map((item, index) => (
          <React.Fragment key={item.title}>
            <TeamMember
              image={item.image}
              alt={item.alt}
              title={item.title}
              description={item.description}
              textLeft={item.textLeft}
            />
            <TeamSeparator key={index.toString()} type={item.separator} />
          </React.Fragment>
        ))}
      </div>
    </section>
  );
}

export default Team;
