import React, { useCallback, useMemo, useRef, useState } from 'react';
import CloseButton from '@/components/modules/CloseButton';
import { Info, Input } from '@/types';
import FormContacts from '@/components/modules/FormContacts';
import IconLink from '@/components/modules/IconLink';
import classNames from 'classnames';
import InfoArrow from '@/assets/images/arrows/freccia-info.svg';

interface Props extends Info {
  openButtonLabel: string;
}

function ContactsPanel({
  phone,
  email,
  social,
  downloadArea,
  info,
  companyData,
  form,
  openButtonLabel,
}: Props) {
  const contacts = useRef<HTMLDivElement>(null);
  const [contactsOpen, setContactsOpen] = useState(false);

  const inputs = useMemo<Input[]>(
    () => [
      {
        type: `email`,
        label: form.emailLabel,
        name: `email`,
        required: true,
      },
      {
        type: `text`,
        label: form.nameLabel,
        name: `name`,
        required: true,
      },
      {
        type: `text`,
        label: form.surnameLabel,
        name: `surname`,
        required: true,
      },
    ],
    []
  );

  const textarea = useMemo(
    () => ({
      label: form.messageLabel,
      name: `message`,
      required: true,
    }),
    []
  );

  const togglePanel = useCallback(() => {
    if (contactsOpen) {
      // Chiudo
      contacts.current.style.transform = ``;
      document.body.classList.remove(`overflow-hidden`);
    } else {
      // Apro
      contacts.current.style.transform = `translateX(100%)`;
      document.body.classList.add(`overflow-hidden`);
    }
    setContactsOpen(!contactsOpen);
  }, [contactsOpen]);

  return (
    <div
      ref={contacts}
      className={classNames(
        `contacts w-screen h-screen lg:h-3/4 lg:max-w-screen-md fixed lg:top-1/4 right-full transition-transform duration-500 z-100`,
        { 'z-110': contactsOpen }
      )}
    >
      <div className="contacts__overflow-x-wrapper relative w-full h-full overflow-x-visible border-[1px] border-red">
        <div className="contacts__open flex w-10 h-[56px] absolute top-[150px] lg:-top-[1px] left-full border-[1px] border-red justify-center items-center bg-white">
          <button
            className="text-red text-20 leading-24 -rotate-90 origin-center"
            onClick={togglePanel}
            type="button"
          >
            {openButtonLabel}
          </button>
        </div>
        <div className="contacts__overflow-y-wrapper w-full flex flex-col h-full pb-10 lg:pb-0 overflow-y-scroll bg-white">
          <div className="contacts__close ml-auto">
            <CloseButton onClick={togglePanel} />
          </div>
          <div className="contacts__general-info w-full py-6 pl-4 pr-22 lg:pr-30 relative flex flex-col justify-end items-end">
            <p className="w-fit font-df-camino font-thin text-17 leading-22 whitespace-pre text-right">
              Orografie
            </p>
            <p className="w-fit font-df-camino font-thin text-17 leading-22 whitespace-pre text-right">
              {email}
            </p>
            <p className="w-fit font-df-camino font-thin text-17 leading-22 whitespace-pre text-right">
              {phone}
            </p>
            <InfoArrow
              className="absolute w-20 lg:w-27 h-2 right-0 top-[40px] lg:top-[82.5px] -translate-y-1/2"
              alt=""
            />
          </div>
          <div className="contacts__form w-full">
            <FormContacts
              title={form.name}
              inputs={inputs}
              submitLabel={form.submitLabel}
              textarea={textarea}
            />
          </div>
          <div className="contacts__info w-full flex flex-col lg:flex-row-reverse">
            <div className="w-full flex flex-col justify-start items-start gap-y-9 lg:gap-y-3 px-6 py-9">
              <div className="w-fit flex flex-col justify-start gap-y-2 lg:gap-y-0">
                <p className="font-df-camino text-18 leading-35">
                  {info.label}
                </p>
                <div className="w-fit flex flex-col">
                  {info.links.map((el, index) => (
                    <a
                      key={index.toString()}
                      className="font-df-camino font-thin text-22 leading-30"
                      href={el.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {el.label}
                    </a>
                  ))}
                </div>
              </div>
              <div className="w-fit flex flex-col justify-start gap-y-2 lg:gap-y-0">
                <p className="font-df-camino text-18 leading-35">
                  {social.label}
                </p>
                <div className="w-fit flex justify-start items-center gap-x-4">
                  {social.links.map((el) => (
                    <IconLink key={el.type} className="w-5 h-5" {...el} />
                  ))}
                </div>
              </div>
              <div className="w-fit flex-col justify-start gap-y-2 lg:gap-y-0">
                <p className="font-df-camino text-18 leading-35">
                  {downloadArea.label}
                </p>
                <div className="w-fit flex flex-col justify-start items-start text-left">
                  {downloadArea.links.map(({ label, file, url }, index) => (
                    <a
                      className="font-df-camino font-thin text-22 leading-30"
                      key={index.toString()}
                      href={file || url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {label}
                    </a>
                  ))}
                </div>
              </div>
            </div>
            <div className="w-full flex flex-col gap-y-6 py-9 px-6 border-t-[1px] lg:border-none border-red">
              <p className="font-df-camino text-18 leading-35">
                {companyData.label}
              </p>
              <p className="font-df-camino font-thin text-14 leading-17 whitespace-pre">
                {companyData.content}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactsPanel;
