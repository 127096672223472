import classNames from 'classnames';
import React from 'react';

export interface Props {
  type: 'none' | 'small' | 'large';
}

function TeamSeparator({ type }: Props) {
  return (
    <div
      className={classNames(`team-separator row-span-2`, {
        'w-20': type === `none`,
        'w-29 lg:w-33': type === `small`,
        'w-39 lg:w-44': type === `large`,
      })}
    />
  );
}

export default TeamSeparator;
