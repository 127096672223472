import React, { useCallback, useEffect, useRef, useContext } from 'react';
import { gsap } from 'gsap';
import { BaseComponentProps } from '@/types';
import { random } from '@/utils/math';
import { app } from '@/utils/app';
import { Context as SettingsContext } from '@/components/layouts/default';

interface Props extends BaseComponentProps {
  className?: string;
  containerAnimation?: React.MutableRefObject<GSAPAnimation>;
  trigger: React.MutableRefObject<HTMLDivElement>;
  movement?: number;
  direction?: 1 | -1;
  horizontal?: boolean;
  markers?: boolean;
}

function Parallax({
  className,
  containerAnimation,
  trigger,
  movement,
  direction,
  horizontal,
  markers = false,
  children,
}: Props) {
  const settingsCtx = useContext(SettingsContext);
  const parallax = useRef<GSAPAnimation>(null);
  const container = useRef<HTMLDivElement>(null);

  const initParallax = useCallback(() => {
    let progress = 0;
    if (parallax.current) {
      progress = parallax.current.progress();
      parallax.current.kill();
    }

    const max = 200;
    const min = 50;
    const randomMovement = random(min, max);

    const axis = horizontal
      ? { x: movement || randomMovement(direction) }
      : { y: movement || randomMovement(direction) };

    parallax.current = gsap.to(container.current, {
      ...axis,
      duration: 2,
      scrollTrigger: {
        containerAnimation: containerAnimation?.current || null,
        trigger: trigger.current,
        start: `top top`,
        scrub: 2,
        markers,
      },
    });

    parallax.current.progress(progress);
  }, []);

  useEffect(() => {
    if (!settingsCtx.parallax) return;
    if (!app.structure.canAnimate) return;
    initParallax();
  }, [app.structure.canAnimate, settingsCtx]);

  return (
    <div ref={container} className={`parallax ${className || ``}`}>
      {children}
    </div>
  );
}

export type { Props };
export default Parallax;
