import React, { useState } from 'react';
import { INavigationItem } from '@/types';
import classNames from 'classnames';
import Arrow from '@/assets/images/arrows/products-menu-arrow.svg';

interface Props extends INavigationItem {
  left: boolean;
  active?: boolean;
  onClick: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    urlAnchor?: any
  ) => void;
}

function ProductsMenuItem({
  label,
  name,
  parent,
  startPoint,
  left,
  active = false,
  onClick,
}: Props) {
  const [isHover, setIsHover] = useState<boolean>(false);
  return (
    <div
      className={classNames(
        `products-menu-item [flex-basis:calc(50%-0.46875rem)] flex`,
        { 'justify-end': left }
      )}
    >
      <div
        className={classNames(`flex justify-center items-center w-max h-fit`, {
          'flex-row': !left,
          'flex-row-reverse': left,
        })}
      >
        <a
          key={name}
          href={`?section=${parent}&subsection=${label.toLowerCase()}`}
          className={classNames(
            `relative flex-none font-light text-metallic-seaweed text-18 leading-22 transition-all duration-500 hover:text-persian-red`,
            {
              'text-persian-red': active,
            }
          )}
          data-anchor={label.toLowerCase()}
          data-start-point={startPoint}
          onClick={onClick}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          {label}
        </a>
        {(active || isHover) && (
          <Arrow
            className={classNames(
              `products-menu-item__arrow text-persian-red h-2 pointer-events-none`,
              {
                'right-full slide-left': left,
                'left-full slide-right': !left,
              }
            )}
          />
        )}
      </div>
    </div>
  );
}

export type { Props };
export default ProductsMenuItem;
