import React from 'react';
import DefaultLayout from '@/components/layouts/default';
import { app } from '@/utils/app';
import HorizontalScrolling from '@/components/modules/HorizontalScrolling';
import Home from '@/components/sections/Home';
import Brand from '@/components/sections/Brand';
import Manifest from '@/components/sections/Manifest';
import Ideation from '@/components/sections/Ideation';
import ProductsHead from '@/components/sections/Products/ProductsHead';
import ProductsBody from '@/components/sections/Products/ProductsBody';
import Emergent from '@/components/sections/Emergent';
import DesignersHead from '@/components/sections/Designers/DesignersHead';
import DesignersBody from '@/components/sections/Designers/DesignersBody';
import Team from '@/components/sections/Team';
import SEO from '@/components/modules/SEO';
import { SSeo } from '@/types/strapi';
import { DynamicModal, Info, Link, Navigation } from '@/types';
import { Props as IconLinkProps } from '@/components/modules/IconLink';
import Logo from '@/assets/images/logo.svg';

interface Props {
  seo: SSeo;
  navigation: Navigation;
  footerIcons: IconLinkProps[];
  footerLink: Link;
  home: any;
  marchio: any;
  manifesto: any;
  ideazione: any;
  prodotti: any;
  emersivi: any;
  designers: any;
  team: any;
  info: Info;
  modal: DynamicModal;
  navigationReady: boolean;
  scrollTo: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    value: string
  ) => void | React.MouseEventHandler<HTMLAnchorElement>;
}

function Index({
  seo,
  navigation,
  footerIcons,
  footerLink,
  home,
  marchio,
  manifesto,
  ideazione,
  prodotti,
  emersivi,
  designers,
  team,
  info,
  modal,
  navigationReady,
  scrollTo,
}: Props) {
  const imageUrl = `https://d98wxao0fcbqm.cloudfront.net/compasso_d_oro_Menzione_d_onore_logo_631d771c2e.webp`;

  return (
    <>
      <DefaultLayout
        navigation={navigation}
        icons={footerIcons}
        footerLink={footerLink}
        info={info}
        modal={modal}
        scrollTo={scrollTo}
        imageUrl={imageUrl}
      >
        {!navigationReady && (
          <div className="fixed inset-0 bg-white z-100 flex flex-col justify-center items-center">
            <div className="w-30 h-30 lg:w-35 lg:h-35">
              <Logo />
            </div>
            <div className="mt-5 text-20">Loading...</div>
          </div>
        )}
        <HorizontalScrolling id="first-horizontal-section" index={0}>
          <Home
            name="home"
            background={home.backgrounds}
            separator={home.separator}
            firstText={home.firstText}
            secondText={home.secondText}
            thirdText={home.thirdText}
            iosFallbackImage={home.iosFallbackImage}
            mobileRatio={1.06}
            desktopRatio={1.6}
          />
          <Brand
            name="brand"
            background={marchio.backgrounds}
            separator={marchio.separator}
            mobileRatio={1.17}
            desktopRatio={1.6}
          />
          <Manifest
            name="manifest"
            content={manifesto.content}
            background={manifesto.backgrounds}
            separator={manifesto.separator}
            mobileRatio={1.48}
            desktopRatio={1.6}
          />
          <Ideation
            name="ideation"
            background={ideazione.backgrounds}
            separator={ideazione.separator}
            entries={ideazione.entries}
            mobileRatio={1.53}
            desktopRatio={1.6}
          />
          <ProductsHead
            name="products"
            navigationItems={app.structure.navigation.products}
            background={prodotti.backgrounds}
            cover={prodotti.cover}
            startPoint={app.structure?.sectionsRanges?.products?.start}
            endPoint={app.structure?.sectionsRanges?.products?.end}
            onClick={scrollTo}
          />
        </HorizontalScrolling>
        <ProductsBody
          name="products-body"
          background={prodotti.bodyBackground}
          items={prodotti.items}
        />
        <HorizontalScrolling id="second-horizontal-section" index={1}>
          <Emergent
            name="emergent"
            background={emersivi.backgrounds}
            separator={emersivi.separator}
            mobileRatio={1.57}
            desktopRatio={1.6}
            headingLogo={emersivi.headingLogo}
            text={emersivi.content}
            elements={emersivi.elements}
            entries={emersivi.entries}
            entriesOddBackground={emersivi.oddBackgrounds}
            entriesSeparator={emersivi.entriesSeparator}
          />
          <DesignersHead
            name="designers"
            background={designers.background}
            gridItems={designers.gridItems}
            navigationItems={app.structure.navigation.designers}
            startPoint={app.structure?.sectionsRanges?.designers?.start}
            endPoint={app.structure?.sectionsRanges?.designers?.end}
            onClick={scrollTo}
          />
        </HorizontalScrolling>
        <DesignersBody designers={designers.list} />
        <HorizontalScrolling id="third-horizontal-section" index={2}>
          <Team
            name="team"
            elements={team.members}
            background={team.backgrounds}
            separator={team.separator}
          />
        </HorizontalScrolling>
      </DefaultLayout>
      <SEO {...seo} />
    </>
  );
}

export default Index;
