import React, { useMemo } from 'react';
import { HorizontalSectionProps } from '@/types';
import Parallax from '@/components/animations/Parallax';
import Fade from '@/components/animations/Fade';
import ImageWrapper from '../ImageWrapper';

const HorizontalSection = React.forwardRef<
  HTMLDivElement,
  HorizontalSectionProps
>(
  (
    {
      name,
      containerAnimation,
      trigger,
      background = [],
      separator,
      mobileRatio,
      desktopRatio,
      children,
    },
    ref
  ) => {
    const separatorTranslateX = useMemo(() => {
      switch (separator?.position) {
        case `left`:
          return `translate-x-0`;
        case `right`:
          return `translate-x-full`;
        case `center`:
          return `translate-x-1/2`;
        default:
          return ``;
      }
    }, [separator]);
    return (
      <section
        id={name}
        ref={ref}
        className="horizontal-section relative h-screen w-[var(--mobile-width)] lg:w-[var(--desktop-width)]"
        style={{
          /* @ts-expect-error named properties are not recognized */
          '--mobile-width': `${mobileRatio * 100}vh`,
          '--desktop-width': `${desktopRatio * 100}vh`,
        }}
      >
        <div className="horizontal-section_bg h-full">
          {background.map((bg, i) => (
            <Parallax
              key={`layer-${i}`}
              className={`${i > 0 ? `absolute inset-0` : `h-full`}`}
              containerAnimation={containerAnimation}
              trigger={trigger}
              horizontal
              movement={bg.parallaxMovement}
            >
              <Fade className="h-full" delay={bg.delay}>
                <ImageWrapper
                  data={bg.data}
                  mobile={bg.mobile}
                  className="h-full"
                  layout="fixed"
                  alt=""
                />
              </Fade>
            </Parallax>
          ))}
        </div>
        <div className="horizontal-section__content absolute inset-0 z-10">
          {children}
          {separator && (
            <Fade delay={separator.delay}>
              <Parallax
                className="horizontal-section__separator absolute top-0 bottom-0 right-0 z-10"
                containerAnimation={containerAnimation}
                trigger={trigger}
                horizontal
                movement={separator.parallaxMovement}
              >
                <div className={`transform ${separatorTranslateX}`}>
                  <ImageWrapper
                    className="h-screen"
                    data={separator.data}
                    mobile={separator.mobile}
                    layout="fixed"
                    objectFit="contain"
                    alt=""
                  />
                </div>
              </Parallax>
            </Fade>
          )}
        </div>
      </section>
    );
  }
);

export default HorizontalSection;
