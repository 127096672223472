import React from 'react';
import { INavigationItem } from '@/types';
import classNames from 'classnames';
import ProductsMenuItem from '@/components/modules/ProductsMenuItem';

interface Props {
  navigationItems: INavigationItem[];
  currentSection: string;
  isVisible: boolean;
  onClick: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    urlAnchor?: any
  ) => void;
}

function ProductsMenu({
  navigationItems = [],
  currentSection,
  isVisible,
  onClick,
}: Props) {
  return (
    <nav
      className={classNames(
        `products-menu hidden fixed top-36 left-1/2 z-40 max-w-100 w-full transform -translate-x-1/2 lg:flex lg:items-start lg:flex-wrap lg:gap-x-3`,
        {
          'fade-out pointer-events-none': !isVisible,
          'fade-in': isVisible,
        }
      )}
    >
      {navigationItems.map((item, i) => (
        <ProductsMenuItem
          key={item.name}
          {...item}
          left={i % 2 === 0}
          active={item.label.toLowerCase() === currentSection}
          onClick={onClick}
        />
      ))}
    </nav>
  );
}

export default ProductsMenu;
