import React from 'react';
import useWindowSize from '@/hooks/useWindowSize';
import './style.scss';

export interface Props {
  label: string;
  name: string;
  required: string;
  value: string;
  onChange: (name: string, value: string) => void;
}

function FormTextArea({ label, required, name, value, onChange }) {
  const size = useWindowSize();

  return (
    <div className="w-full flex flex-col align-top">
      <p className="font-df-camino text-18 leading-30">{label}</p>
      <textarea
        rows={size < 1024 ? 5 : 3}
        required={required}
        name={name}
        value={value}
        onChange={(e) => onChange(name, e.target.value)}
        className="w-full text-18 z-1 lg:-translate-y-[22.5px] leading-30 lg:leading-75 align-text-top bg-transparent resize-none focus:outline-none overflow-hidden font-extralight"
      />
    </div>
  );
}

export default FormTextArea;
