import React, { useEffect, useState } from 'react';

function SwitchLanguage() {
  const [path, setPath] = useState(``);
  const [label, setLabel] = useState(``);

  useEffect(() => {
    const pathName = window.location.pathname;

    if (pathName.includes(`/en`)) {
      setPath(`/`);
      setLabel(`IT`);
    } else {
      setPath(`/en`);
      setLabel(`EN`);
    }
  }, []);

  return (
    <div className="fixed top-[105px] lg:top-[calc(25%_-_49px)] left-0 z-100 w-[49px] h-10 bg-white border-[1px] border-l-0 border-red flex justify-center items-center">
      <a
        className="-rotate-90 text-20 leading-24 text-red text-center"
        href={path}
      >
        {label}
      </a>
    </div>
  );
}

export default SwitchLanguage;
