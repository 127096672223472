import React from 'react';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import ImageWrapper from '@/components/modules/ImageWrapper';
import ReactMarkdown from 'react-markdown';

interface Props {
  name: string;
  portrait: {
    alt: string;
    data: IGatsbyImageData;
  };
  hint: string;
  images: {
    alt: string;
    data: IGatsbyImageData;
  }[];
  background: IGatsbyImageData;
}

function Designer({ name, portrait, hint, images, background }: Props) {
  return (
    <div id={name} className="designer w-full h-fit relative pt-30 lg:pt-60">
      <div className="designer__grid container grid grid-cols-1 gap-y-6 md:grid-cols-2 md:gap-y-0 lg:grid-cols-3">
        <div className="designer__hint font-light text-18 text-black leading-22 md:pr-10">
          <ReactMarkdown>{hint}</ReactMarkdown>
        </div>
        <div className="designer__images space-y-6 -z-10">
          <ImageWrapper
            className="w-full aspect-square"
            data={portrait.data}
            alt={portrait.alt}
          />
          {images.map((image, i) => (
            <ImageWrapper
              className="w-full object-contain"
              key={`image-${i}`}
              data={image.data}
              alt={image.alt}
            />
          ))}
        </div>
      </div>
      <div className="designer__background hidden md:block w-screen h-auto absolute top-1/2 left-0 -z-1">
        <ImageWrapper className="w-full h-auto" data={background} alt="" />
      </div>
    </div>
  );
}

export type { Props };
export default Designer;
